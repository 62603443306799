<template>
    <div class="sidebar">
        <el-menu
            class="sidebar-el-menu"
            :default-active="onRoutes"
            :collapse="collapse"
            background-color="#324157"
            text-color="#bfcbd9"
            active-text-color="#20a0ff"
            unique-opened
            router
        >
            <template v-for="item in items" >
                <template v-if="item.subs && (isYuncang || item.isShow)">
                    <el-sub-menu :index="item.index" :key="item.index" >
                        <template #title>
                            <i :class="item.icon"></i>
                            <span>{{ item.title }}</span>
                        </template>
                        <template v-for="subItem in item.subs">
                            <el-sub-menu
                                v-if="subItem.subs"
                                :index="subItem.index"
                                :key="subItem.index"
                            >
                                <template #title>{{ subItem.title }}</template>
                                <el-menu-item
                                    v-for="(threeItem, i) in subItem.subs"
                                    :key="i"
                                    :index="threeItem.index"
                                >{{ threeItem.title }}</el-menu-item>
                            </el-sub-menu>
                            <el-menu-item
                                v-else
                                :index="subItem.index"
                                :key="subItem.index"
                            >{{ subItem.title }}</el-menu-item>
                        </template>
                    </el-sub-menu>
                </template>
                <template v-else-if="isYuncang || item.isShow">
                    <el-menu-item :index="item.index" :key="item.index">
                        <i :class="item.icon"></i>
                        <template #title>{{ item.title }}</template>
                    </el-menu-item>
                </template>
            </template>
        </el-menu>
    </div>
</template>

<script>
// import bus from "../common/bus";
export default {
    data() {
        return {
            isYuncang:true,
            items: [
                {
                    icon: "iconfont icon-shebei1 myicon",
                    index: "/baselandmanage",
                    title: "实况概览",
                    isShow: true
                },
                /*{
                    icon: "iconfont icon-zhongzhi myicon",
                    index: "1",
                    title: "种植管理",
                    isShow: true,
                    subs: [
                        {
                            index: "/plantplan",
                            title: "种植计划"
                        },
                        {
                            index: "/worktask",
                            title: "农事任务"
                        },
                        {
                            index: "/workrecord",
                            title: "农事记录"
                        }
                    ]
                },*/
                {
                    icon: "iconfont icon-shexiangtou myicon",
                    index: "/cameramanage",
                    title: "监控管理",
                    isShow: true
                },
                {
                    icon: "iconfont icon-shebei myicon",
                    index: "/devicemanage",
                    title: "设备管理",
                    isShow: true
                },
              {
                icon: "iconfont icon-shebei myicon",
                index: "/workermanage",
                title: "员工管理",
                isShow: false,
              },
              {
                icon: "iconfont icon-zhongzhi myicon",
                index: "5",
                title: "排班管理",
                isShow: false,
                subs: [
                  {
                    index: "/classmanage",
                    title: "班次管理"
                  },
                  {
                    index: "/classplanmanage",
                    title: "自动排班"
                  }
                ]
              },
              {
                icon: "iconfont icon-shebei myicon",
                index: "/clockmanage",
                title: "考勤管理",
                isShow: false
              },
              {
                icon: "iconfont icon-shebei myicon",
                index: "7",
                title: "薪资管理",
                isShow: false,
                subs: [
                  {
                    index: "/paysetting",
                    title: "薪资设置"
                  },
                  {
                    index: "/paysheetmanage",
                    title: "工资单管理"
                  }
                ]
              }
            /*  {
                icon: "el-icon-lx-calendar",
                index: "/other",
                title: "其他"
              },*/
                /*{
                    icon: "el-icon-lx-cascades",
                    index: "/table",
                    title: "基础表格"
                },
                {
                    icon: "el-icon-lx-copy",
                    index: "/tabs",
                    title: "tab选项卡"
                },
                {
                    icon: "el-icon-lx-calendar",
                    index: "3",
                    title: "表单相关",
                    subs: [
                        {
                            index: "/form",
                            title: "基本表单"
                        },
                        //         {
                        //             index: "3-2",
                        //             title: "三级菜单",
                        //             subs: [
                        //                 {
                        //                     index: "editor",
                        //                     title: "富文本编辑器"
                        //                 },
                        //                 {
                        //                     index: "markdown",
                        //                     title: "markdown编辑器"
                        //                 }
                        //             ]
                        //         },
                        {
                            index: "/upload",
                            title: "文件上传"
                        }
                    ]
                },
                {
                    icon: "el-icon-lx-emoji",
                    index: "/icon",
                    title: "自定义图标"
                },
                {
                    icon: "el-icon-pie-chart",
                    index: "/charts",
                    title: "schart图表"
                },
                // {
                //     icon: "el-icon-rank",
                //     index: "6",
                //     title: "拖拽组件",
                //     subs: [
                //         {
                //             index: "drag",
                //             title: "拖拽列表"
                //         },
                //         {
                //             index: "dialog",
                //             title: "拖拽弹框"
                //         }
                //     ]
                // },
                {
                    icon: "el-icon-lx-global",
                    index: "/i18n",
                    title: "国际化功能"
                },
                {
                    icon: "el-icon-lx-warn",
                    index: "/7",
                    title: "错误处理",
                    subs: [
                        {
                            index: "/permission",
                            title: "权限测试"
                        },
                        {
                            index: "/404",
                            title: "404页面"
                        }
                    ]
                },
                {
                    icon: "el-icon-lx-redpacket_fill",
                    index: "/info/donate",
                    title: "支持作者"
                }*/
            ]
        };
    },
    mounted(){
        try {
            let username = localStorage.getItem("userInfo") == null ? null : JSON.parse(localStorage.getItem("userInfo")).company.account;
            if ("user" == username) {
                this.items = [
                    {
                        icon: "iconfont icon-shebei myicon",
                        index: "/devicemanage",
                        title: "设备管理"
                    },
                ]
            }
        }catch (e) {

        }

       let domain = window.location.host;
        console.error(">>>>>>>>", domain)
        if (domain.indexOf('yuncangai') == -1){
          this.isYuncang = false
        }

    },
    computed: {
        onRoutes() {
          //  let j = this.$route.path.replace("/", "");
            //return this.$route.path.replace("/", "");
            //console.error(">>>> ", this.$route.path);
            return this.$route.path;
           // return this.$route.path.replace("/", "");
        },
        collapse(){
            return this.$store.state.collapse
        }
    }
};
</script>

<style scoped>
    .myicon{
        vertical-align: middle;
        margin-right: 7.5px;
        margin-left: 2.5px;
        width: 24px;
        text-align: center;
        font-size: 18px;
    }
    .sidebar {
        display: block;
        position: absolute;
        left: 0;
        top: 70px;
        bottom: 0;
        overflow-y: scroll;
    }
    .sidebar::-webkit-scrollbar {
        width: 0;
    }
    .sidebar-el-menu:not(.el-menu--collapse) {
        width: 250px;
    }
    .sidebar > ul {
        height: 100%;
    }
</style>
