<template>
  <router-view />
</template>

<script>
  export default {};
</script>

<style>
  @import "./assets/css/main.css";
  @import "./assets/css/color-dark.css";
  @import "//at.alicdn.com/t/font_2724348_6tq8ge4q3gq.css";
</style>
