import { createApp } from 'vue'
/*import ElementPlus from 'element-plus';
import 'element-plus/lib/theme-chalk/index.css';*/
import installElementPlus from './plugins/element'
import App from './App.vue'
import router from './router'
import store from './store'
import './assets/css/icon.css'
import 'dayjs/locale/zh-cn'
import layer from "layui-layer";
import $ from 'jquery'
import ECharts from 'vue-echarts'
import { use } from "echarts/core";
import apiUtils from "./api/index";
import axios from 'axios'


/* eslint-disable */

// 手动引入 ECharts 各模块来减小打包体积
import {
    CanvasRenderer
} from 'echarts/renderers'
import {
    LineChart,
    BarChart,
} from 'echarts/charts'
import { UniversalTransition } from 'echarts/features';
import {
    TitleComponent,
    LegendComponent,
    DataZoomComponent,
    GridComponent,
    TooltipComponent,
    ToolboxComponent,
    VisualMapComponent,
    MarkLineComponent,
    MarkPointComponent,
    MarkAreaComponent
} from 'echarts/components'

use([
    CanvasRenderer,
    LineChart,
    BarChart,
    GridComponent,
    TooltipComponent,
    TitleComponent,
    LegendComponent,
    DataZoomComponent,
    ToolboxComponent,
    VisualMapComponent,
    MarkLineComponent,
    MarkPointComponent,
    MarkAreaComponent,
    UniversalTransition
]);

const app = createApp(App)
installElementPlus(app)
app.config.globalProperties.$ = $
app.config.globalProperties.layer = window.layer
app.config.globalProperties.apiUtils = apiUtils
app.config.globalProperties.axios = axios
app
    .component('v-chart', ECharts)
    .use(store)
    .use(router)
    .mount('#app')
