import axios from 'axios';
import router from '../router'

const service = axios.create({
    // process.env.NODE_ENV === 'development' 来判断是否开发环境
    // easy-mock服务挂了，暂时不使用了
    // baseURL: 'https://www.easy-mock.com/mock/592501a391470c0ac1fab128',
    headers: {
        'Content-Type': 'application/json',
       // 'Authorization' : 'Bearer '+(localStorage.getItem("userInfo") == null ? "" : JSON.parse(localStorage.getItem("userInfo")).token),
    },
    timeout: 30000
});

service.interceptors.request.use(
    config => {
        return config;
    },
    error => {
        console.log("service.interceptors.request.use >>>> ",error);
        return Promise.reject();
    }
);

service.interceptors.response.use(
    response => {
        if (response.status === 200) {
            if (response.data && response.data.code
                && (response.data.code == 39990
                || response.data.code == 39991
                || response.data.code == 39992)){
             //  console.error(router.currentRoute.value.path)
                router.push("/login");
            }
            return response.data;
        } else {
            Promise.reject();
        }
    },
    error => {
        console.log(error);
        return Promise.reject();
    }
);

export default service;
